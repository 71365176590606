const Portfolio = () => {
    return (
        <section id="portfolio" className="portfolio section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Portfolio</h2>
                    <p>I have developed these applications. You can have a quick look-up.</p>
                </div>

                {/*<div className="row">*/}
                {/*    <div className="col-lg-12 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">*/}
                {/*        <ul id="portfolio-flters">*/}
                {/*            <li data-filter="*" className="filter-active">All</li>*/}
                {/*            <li data-filter=".filter-app">App</li>*/}
                {/*            <li data-filter=".filter-card">Card</li>*/}
                {/*            <li data-filter=".filter-web">Web</li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" />
                            <div className="portfolio-info">
                                <h4><a href="https://indanetrader.com" target="_blank">Indanetrader</a></h4>
                                {/*<p>App</p>*/}
                                {/*<div className="portfolio-links">*/}
                                {/*    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="IndaneTrader"><i className="bx bx-plus"></i></a>*/}
                                {/*    <a href="portfolio-details.html" className="portfolio-details-lightbox" data-glightbox="type: external" title="Portfolio Details"><i className="bx bx-link"></i></a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                        <div className="portfolio-wrap">
                            <img src="assets/img/portfolio/portfolio-2.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-info">
                                <h4><a href="https://missionmilliontrees.in" target="_blank">Missionmilliontrees</a></h4>                               
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-lg-4 col-md-6 portfolio-item filter-web">*/}
                    {/*    <div className="portfolio-wrap">*/}
                    {/*        <img src="assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt="" />*/}
                    {/*        <div className="portfolio-info">*/}
                    {/*            <h4>Web 3</h4>*/}
                    {/*            <p>Web</p>*/}
                    {/*            <div className="portfolio-links">*/}
                    {/*                <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>*/}
                    {/*                <a href="portfolio-details.html" className="portfolio-details-lightbox" data-glightbox="type: external" title="Portfolio Details"><i className="bx bx-link"></i></a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                  

                </div>

            </div>
        </section>);
}

export default Portfolio
