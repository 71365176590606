import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Main from './components/Main'

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div>
                <Header />
                <Hero />
                <Main />
                <Footer />
            </div>
        );
    }
}
