
import * as React from 'react';
import * as Common from './../helpers/CommonMethods';
import Typed from 'react-typed';

const Hero = () => {

    return (
        <section id="hero" className="d-flex flex-column justify-content-center">
            <div className="container" data-aos="zoom-in" data-aos-delay="100">
                <h1>Nalla Sagar Choudhury</h1>
                <p>I'm a &nbsp;
                    <Typed
                        strings={['Developer', 'Stock Trader', 'Freelancer', 'Photographer', 'Self Financing Coach']}
                        loop={true}
                        typeSpeed={100}
                        backSpeed={50}
                        backDelay={2000}
                />
                </p>
                <div className="social-links">
                    <a href="whatsapp://send?phone=+917008517377" className="google-plus" target="_blank"><i className="bx bxl-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/in/nalla-sagar-choudhury-62b5a2128/" className="linkedin" target="_blank"><i className="bx bxl-linkedin"></i></a>
                    <a href="https://www.facebook.com/sagar.choudhury.355" className="facebook" target="_blank"><i className="bx bxl-facebook"></i></a>
                    <a href="https://www.instagram.com/imnsagar/" className="instagram" target="_blank"><i className="bx bxl-instagram"></i></a>
                </div>
            </div>
        </section>
    );
}

export default Hero;