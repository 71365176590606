import { useState } from 'react';
import axios from 'axios';

const Contact = () => {
    //const apiPath = "https://app-sagar-dev.azurewebsites.net/api/app/sendmessage";
    const apiPath = "https://nsagar.com/api/app/sendmessage"
    const [addMsgValues, setAddMsgValues] = useState({
        name: '',
        mobile: '',
        email: '',
        message: ''
    });

    const handleAddMsgValueChange = (prop) => (event) => {
        setAddMsgValues({ ...addMsgValues, [prop]: event.target.value });
    };
    const [sending, setSending] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false)
    const [validationMsg, setValidationMsg] = useState("");
    const handleSendMessage = () => {
        if (addMsgValues.name.trim() == "" || addMsgValues.email.trim() == "" || addMsgValues.mobile.trim() == "" || addMsgValues.message.trim() == "") {
            setValidationMsg("All fields are mandatory");
        } else {
            setValidationMsg("");
            setSending(true);

            try {
                axios.post(apiPath, addMsgValues)
                    .then(response => {
                        if (response.status === 200) {
                            setErrorMsg(false);
                            setSuccessMsg(true);
                        }
                        setSending(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setErrorMsg(true);
                        setSuccessMsg(false);
                        setSending(false);
                    });
            }
            catch (e) {
                setErrorMsg(true);
                setSuccessMsg(false);
                setSending(false);
            }
        }
    }

    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Contact</h2>
                    <p>If you want to opt any service or wants to know more details about the services please feel free to reachout to me.</p>
                </div>

                <div className="row mt-1">

                    <div className="col-lg-4">
                        <div className="info">
                            <div className="address">
                                <i className="bi bi-geo-alt"></i>
                                <h4>Location:</h4>
                                <p>#20, RMS Colony, Battarahalli, Bengaluru</p>
                            </div>

                            <div className="email">
                                <i className="bi bi-envelope"></i>
                                <h4>Email:</h4>
                                <p>connect@nsagar.com</p>
                            </div>

                            <div className="phone">
                                <i className="bi bi-phone"></i>
                                <h4>Call/WhatsApp:</h4>
                                <p>+91 7008517377</p>
                            </div>

                        </div>

                    </div>

                    <div className="col-lg-8 mt-5 mt-lg-0">

                        <form role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name*" onChange={handleAddMsgValueChange('name')} required />
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input type="text" className="form-control" name="mobile" id="subject" placeholder="Your Mobile*" onChange={handleAddMsgValueChange('mobile')} required />
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email*" onChange={handleAddMsgValueChange('email')} required />
                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message*" onChange={handleAddMsgValueChange('message')} required></textarea>
                            </div>
                            <div className="my-3">
                                <span style={{ color: "red" }}>{validationMsg}</span>
                                {(sending) ?
                                    <div className="loading">Sending...</div> : null}
                                {(successMsg) ?
                                    <div className="sent-message">Your message has been sent. Thank you!</div> : null}
                                {(errorMsg) ?
                                    <div className="error-message">Something went wrong. please try again later !!</div> : null}
                            </div>
                            <div className="text-center">
                                <button type="button" disabled={addMsgValues.name.trim() == "" || addMsgValues.email.trim() == "" || addMsgValues.mobile.trim() == "" || addMsgValues.message.trim() == ""} onClick={handleSendMessage}>
                                    Send Message
                                </button>
                            </div>
                        </form>

                    </div>

                </div>

            </div>
        </section>
    );
}
export default Contact;