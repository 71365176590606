

const Header = () => {

    const handleMobileClick = (e) => {
        document.querySelector('body').classList.toggle('mobile-nav-active');
        let navbarToggle = document.querySelectorAll('.mobile-nav-toggle');
        if (navbarToggle != undefined) {
            navbarToggle[0].classList.toggle('bi-list')
            navbarToggle[0].classList.toggle('bi-x')
        }
    }

    document.querySelectorAll('.scrollto').forEach(item => {
        item.addEventListener('click', event => {

            let body = document.querySelector('body');
            if (body.classList.contains('mobile-nav-active')) {
                body.classList.remove('mobile-nav-active')
                let navbarToggle = document.querySelectorAll('.mobile-nav-toggle');
                if (navbarToggle != undefined) {
                    navbarToggle[0].classList.toggle('bi-list')
                    navbarToggle[0].classList.toggle('bi-x')
                }

            }

            document.querySelectorAll('.scrollto').forEach(ele => {
                ele.classList.remove('active');
            })

            item.classList.add('active');

        })
    });

    let navbarlinks = [...document.querySelectorAll('#navbar .scrollto')];

    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = document.querySelector(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active');
            } else {
                navbarlink.classList.remove('active');
            }
        })
    }
    window.addEventListener('load', navbarlinksActive);
    document.addEventListener('scroll', navbarlinksActive);

    return (
        <>
            <i className="bi bi-list mobile-nav-toggle d-xl-none" onClick={handleMobileClick}></i>

            <header id="header" className="d-flex flex-column justify-content-center">

                <nav id="navbar" className="navbar nav-menu">
                    <ul>
                        <li><a href="#hero" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>Home</span></a></li>
                        <li><a href="#about" className="nav-link scrollto"><i className="bx bx-user"></i> <span>About</span></a></li>
                        <li><a href="#resume" className="nav-link scrollto"><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
                        <li><a href="#portfolio" className="nav-link scrollto"><i className="bx bx-book-content"></i> <span>Portfolio</span></a></li>
                        <li><a href="#services" className="nav-link scrollto"><i className="bx bx-server"></i> <span>Services</span></a></li>
                        <li><a href="#contact" className="nav-link scrollto"><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
                    </ul>
                </nav>

            </header>


        </>
    );
}

export default Header;