const About = () => {
    return (
        <>
            <section id="about" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>About</h2>
                        <p></p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <img src="assets/img/profile-img.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="col-lg-8 pt-4 pt-lg-0 content">
                            <h3>Full Stack Developer</h3>
                            <p class="fst-italic">
                                
                            </p>
                            <div class="row">
                                <div class="col-lg-6">
                                    <ul>                                      
                                        <li><i class="bi bi-chevron-right"></i> <strong>Website:</strong> <span><a href="https://nsagar.com">https://nsagar.com</a></span></li>
                                        <li><i class="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+91 70085 17377</span></li>
                                        <li><i class="bi bi-chevron-right"></i> <strong>City:</strong> <span>Bengaluru, India</span></li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <ul>                                       
                                        <li><i class="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>B.Tech</span></li>
                                        <li><i class="bi bi-chevron-right"></i> <strong>Email:</strong> <span>connect@nsagar.com</span></li>
                                        <li><i class="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                                    </ul>
                                </div>
                            </div>
                            <p>
                              
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default About;