
import { ProgressBar, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Skills = () => {
    return (
        <>
            <section id="skills" className="skills section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Skills</h2>
                        {/*<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>*/}
                    </div>

                    <div className="row skills-content">

                        <div className="col-lg-6">

                            
                            <div className="progressBar progress">
                                <span className="skill">.Net Core</span>
                                <ProgressBar now={80} label={ '80%' } animated />
                            </div>

                            <div className="progressBar progress">
                                <span className="skill">MS SQL</span>
                                <ProgressBar now={90} label={'90%'} animated />
                            </div>

                            <div className="progressBar progress">
                                <span className="skill">React</span>
                                <ProgressBar now={80} label={'80%'} animated />
                            </div>

                        </div>

                        <div className="col-lg-6">

                            <div className="progressBar progress">
                                <span className="skill">Angular</span>
                                <ProgressBar now={85} label={'85%'} animated />
                            </div>

                            <div className="progressBar progress">
                                <span className="skill">Azure</span>
                                <ProgressBar now={70} label={'70%'} animated />
                            </div>

                            <div className="progressBar progress">
                                <span className="skill">DevOps</span>
                                <ProgressBar now={75} label={'75%'} animated />
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    );
}
export default Skills;