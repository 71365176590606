const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <h3>Nalla Sagar Choudhury</h3>
                    <p></p>
                    <div className="social-links">
                        <a href="whatsapp://send?phone=+917008517377" className="google-plus" target="_blank"><i className="bx bxl-whatsapp"></i></a>
                        <a href="https://www.linkedin.com/in/nalla-sagar-choudhury-62b5a2128/" className="linkedin" target="_blank"><i className="bx bxl-linkedin"></i></a>
                        <a href="https://www.facebook.com/sagar.choudhury.355" className="facebook" target="_blank"><i className="bx bxl-facebook"></i></a>
                        <a href="https://www.instagram.com/imnsagar/" className="instagram" target="_blank"><i className="bx bxl-instagram"></i></a>
                    </div>
                    <div className="copyright">
                        &copy; Copyright <strong><span>Nalla Sagar Choudhury</span></strong>. All Rights Reserved
                    </div>
                    <br />
                    <div className="credits">
                        Designed with <i style={{ color: "red" }} className="bi bi-heart-fill"></i> by <a href="#hero" style={{ textDecoration: "none" }}>Sagar</a>
                        
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;