const Resume = () => {
    return (
        <section id="resume" className="resume">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Resume</h2>
                    <p>I want to work in a challenging and healthy environment so that I can extract best out of me to serve the Organization in its success path with a happy personal growth.   Along with that I want to grow my business in web development and self-financing to serve as many people as possible.</p>
                </div>

                <div className="row">

                    <div className="col-lg-6">
                        <h3 className="resume-title">Sumary</h3>
                        <div className="resume-item pb-0">
                            <h4>Nalla Sagar Choudhury</h4>
                            <p><em>I am currently working at <b>Bajaj Finance Ltd</b>, Bengaluru as a <b>Lead Software Architect</b>. I have an experience of <b>8 years</b> in Software Development.</em></p>
                            <ul>
                                <li>#20, RMS Colony, Battarahalli, Bengaluru</li>
                                <li>+91 7008517377</li>
                                <li>connect@nsagar.com</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Professional Experience</h3>
                        <div className="resume-item">
                            <h4>Lead Software Architect</h4>
                            <h5>Present</h5>
                            <p><em><b>Bajaj Finance Ltd, Bengaluru</b></em></p>
                            <ul>
                                <li>Lead in the planning, development, and implementation of the project, architecture and production deployment management.</li>
                                <li>Delegate tasks to 16 members of the development team and provide counsel on all aspects of the project. </li>
                                <li>Supervise the assessment of all project work in order to ensure quality and accuracy of the code.</li>
                            </ul>
                        </div>
                        {/*<div className="resume-item">*/}
                        {/*    <h4>Consultant</h4>*/}
                        {/*    <h5>2018 - 2020</h5>*/}
                        {/*    <p><em><b>Mercedes Benz Research & Development India, Bengaluru</b></em></p>*/}
                        {/*    <ul>*/}
                        {/*        <li>Developed a complete project alone</li>*/}
                        {/*        <li>Managed up to 3 projects or tasks at a given time while under pressure</li>*/}
                        {/*        <li>Recommended and consulted with clients on the most appropriate solution development</li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<div className="resume-item">*/}
                        {/*    <h4>Software Engineer Product Development</h4>*/}
                        {/*    <h5>2016 - 2018</h5>*/}
                        {/*    <p><em><b>Harman Connected Services, Bengaluru</b></em></p>*/}
                        {/*    <ul>*/}
                        {/*        <li>Worked as a key developer of the team</li>*/}
                        {/*        <li>Project management, support</li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<div className="resume-item">*/}
                        {/*    <h4>Software Engineer</h4>*/}
                        {/*    <h5>2015 - 2016</h5>*/}
                        {/*    <p><em><b>Infolink Technologies Pvt Ltd, Bengaluru</b></em></p>*/}
                        {/*    <ul>*/}
                        {/*        <li>Worked as a key developer and handling a project</li>*/}
                        {/*        <li>Dealing with multiple clients to re-structure the application as per their requirement</li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                    </div>
                   
                    <div className="col-lg-6">
                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>Bachelor of Technology (BTech)</h4>
                            <h5>2009 - 2013</h5>
                            <p><em>GIIT, Brahmapur, Odisha</em></p>
                            <p>I have completed my BTech from GIIT, Brahmapur affiliated to Biju Patnaik University of Technology, Rourkela, Odisha </p>
                        </div>
                        <div className="resume-item">
                            <h4>12<sup>th</sup> (Science)</h4>
                            <h5>2007 - 2009</h5>
                            <p><em>Vikram Dev Junior College, Jeypore, Odisha</em></p>
                            <p></p>
                        </div>
                    </div>

                  
                </div>

            </div>
        </section>
    );
}

export default Resume;